@import url("https://fonts.googleapis.com/css?family=Advent+Pro:100,200,300,400,500,600,700|Lato:100,100i,300,300i,400,400i,700,700i,900,900i");

$primary: "Lato", sans-serif;
$secondary: "Advent Pro", sans-serif;

body {
  font-family: $primary; 
  font-size: 16px;

  @include breakpoint (sm){
    font-size: 24px;
  }
}

a,
.button {
  font-family: $secondary;
  color: $black;
  text-decoration: none;
  font-weight: 700;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 { font-family: $secondary; }