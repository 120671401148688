//  breakpoints -------------------------------------------------------------------------------
@mixin breakpoint($class) {
  @if $class == mobile-only {
    @media (max-width: 359px) { @content; }
  }
  
  @else if $class == small-only {
    @media (max-width: 787px) { @content; }
  }

  @else if $class == tablet-only {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == xs {
    @media (min-width: 360px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1280px) { @content; }
  }

  @else if $class == xl {
    @media (min-width: 1440px) { @content; }
  }

  @else if $class == xxl {
    @media (min-width: 1920px) { @content; }
  }
}

// margin -------------------------------------------------------------------------------
@mixin m($value) {
  margin: $value;
}

@mixin my($value) {
  margin-top: $value;
  margin-bottom: $value;
}

@mixin mt($value) {
  margin-top: $value;
}

@mixin mb($value) {
  margin-bottom: $value;
}

@mixin mx($value) {
  margin-left: $value;
  margin-right: $value;
}

@mixin mr($value) {
  margin-right: $value;
}

@mixin ml($value) {
  margin-left: $value;
}

// padding -------------------------------------------------------------------------------
@mixin p($value) {
  padding: $value;
}

@mixin py($value) {
  padding-top: $value;
  padding-bottom: $value;
}

@mixin pt($value) {
  padding-top: $value;
}

@mixin pb($value) {
  padding-bottom: $value;
}

@mixin px($value) {
  padding-left: $value;
  padding-right: $value;
}

@mixin pr($value) {
  padding-right: $value;
}

@mixin pl($value) {
  padding-left: $value;
}


.mobile-only, 
%mobile-only {
  @include breakpoint (xs){ display: none; }
}

.small-only, 
%small-only {
  @include breakpoint (sm) { display: none; }
}

.desktop-only, 
%desktop-only {
  @include breakpoint (small-only) { display: none; }
}

// mixins -------------------------------------------------------------------------------
@mixin border {
  border: 12px $black solid;
}

@mixin border-x {
  border-top: 4px $black solid;
  border-bottom: 4px $black solid;
}

@mixin center($direction:row) {
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-direction: $direction;
}

@mixin flex($justify) {
  display: flex;
  justify-content: $justify;
  align-items: center;
}

@mixin transition($all) {
  transition: $all 0.5s ease;
}
