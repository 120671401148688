/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  font-size: 1em;
	// overflow-x: hidden;
	max-width: 100vw;
	
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*{
	&,
	&:before,
	&:after{
		box-sizing: border-box;
	}
}

html { font-size: 18px; }

h1, h2, h3, h4, h5 {
  font-weight: 400;
  line-height: 1.15;
}

h1 { font-size: 4.209em; }
h2 { font-size: 3.157em; }
h3 { font-size: 2.369em; }
h4 { font-size: 1.777em; }
h5 { font-size: 1.333em; }

p, 
a { font-size: 1em; }

small, 
.text_small { font-size: 0.75em; }

img, 
svg {
  display: block;
	max-width: 100%;
	height: auto;
  margin-right: auto;
  margin-left: auto;
}

a {
  text-decoration: none;
  color: black;
}