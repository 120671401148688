// header ------------------------------------------------------------------------------

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;

  img {
    margin: 0;
    width: 54px;
  }
}

// side nav ------------------------------------------------------------------------------

.info {
  position: fixed; 
  display: flex;
  flex-direction: row-reverse;
  min-width: 100vh;
  
  margin-left: 36px;
  margin-top: 72px;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left;

  a {
    display: inline-block;

    &:after {
      content: '—';
      font-weight: 300;
    }

    &:first-of-type {
      &:after { content: ''; }
    }
  }
}

// top sentence ------------------------------------------------------------------------------

.sentence {
  position: relative;
  padding: 16px 16px 16px 88px;

  .list {
    font-family: $secondary;
    font-weight: 700;
    display: inline-block !important;
    position: relative;
    font-size: 1em;
    padding-bottom: 8px; 
  }

  .list .button {
    display: none;
    width: 320px;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 2px;
    transition: all 3s;
  }
}

.ashlee-btn:hover { cursor: pointer; }

.ashlee {
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-60%);

  img {
    height: 30vh;
    width: auto;
  }

  @media (min-width: 768px) {
    img { height: 60vh; }
  }
}

// projects - homepage ------------------------------------------------------------------------------

.projects {
  top: 0;
  margin-left: 88px;
  min-width: auto;
  @include flex(flex-start);
  flex-wrap: wrap;

  &:hover a { opacity: 0.3; }
  a {
    display: block;
    font-size: 56px;
    margin: 24px 0;
    @include transition(opacity);

    &:hover { opacity: 1; }

    @include breakpoint (xs){
      font-size: 80px; 
    }

    @include breakpoint (sm){
      font-size: 180px;
    }

    @include breakpoint(md){
      font-size: 220px;
    }

    @include breakpoint (xxl){
      font-size: 360px;
    }
  }

  img {
    opacity: 0;
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    height: 65vh;
    width: auto;
    pointer-events: none;
    z-index: -1;

    @include transition(opacity);
  }

  a:hover img { opacity: 1; }
}

// projects show page ------------------------------------------------------------

.copy {
  position: fixed;
  top: 0; 
  right: 0;
  z-index: 10;
  padding: 16px;
  text-align: right;
  max-width: 230px;

  @include breakpoint (sm){
    max-width: 520px;
  }
}

.client-copy {
  font-weight: 700;

  @include breakpoint (sm){
    font-size: 96px;
  }
}

.desc-copy {
  font-weight: 300;
  padding-top: 8px;
}

.content {
  width: 100%;
  height: auto;
  @include flex(flex-start);
  overflow: auto;
}

.images-wrapper { padding: 88px 0; }

.images {
  &:hover { cursor: ew-resize; }
  @include flex(center);

  flex-wrap: wrap;
  margin-left: 88px;
  padding-top: 40px; 
  padding-bottom: 40px; 
  padding-right: 24px;
  width: 90vw;

  @include breakpoint (sm){
    padding-top: 88px; 
    padding-bottom: 88px; 
  }

  img {
    position: relative;
    max-height: 80vh;
    min-height: 30vh;
    margin: 0 24px;

    @include breakpoint (xs){
      max-height: 60vh;
    }

    @include breakpoint (sm){
      height: 36vw;
    }
  }
}